import { manuallyGenerateHanfaReport } from 'services/apiService';
import { useMutation, useQueryClient } from 'react-query';

export function useHanfaManualGeneration() {
  const queryClient = useQueryClient();

  const { mutate: generateHanfaReport } = useMutation({
    mutationFn: manuallyGenerateHanfaReport,
    onSuccess: () => {
      queryClient.invalidateQueries(['hanfa-reports']);
    },
  });

  return { generateHanfaReport };
}
