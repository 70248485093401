import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { thousandSeparatorRound } from 'utils';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import DealStatus from 'components/deal-status';
import AddressLink from 'components/address-link';

const calculatePercentage = (tokensReceived, tokensExpected) => {
  if (!tokensReceived || !tokensExpected) return 0.0;
  return parseFloat((tokensReceived / tokensExpected) * 100).toFixed(2);
};

const AllDealsTableRow = ({
  dealId,
  dealName,
  isOldDeal,
  ticker,
  dealStatus,
  batchesReceived,
  totalBatches,
  tokensReceived,
  tokensExpected,
  claimers,
  dateOfLastDistribution,
}) => {
  const history = useHistory();
  const handleRowClick = () => {
    history.push(`/all-deals/${dealId}?isOldDeal=${isOldDeal}`, {
      pathname: window.location.pathname,
    });
  };

  const formattedDate = (date) => {
    if (!date) return '-';
    return moment(date).format('YYYY-MM-DD, HH:mm');
  };

  return (
    <TableRow onClick={handleRowClick}>
      <TableCell>{dealName}</TableCell>
      <TableCell>{ticker}</TableCell>
      <TableCell>
        <DealStatus dealStatus={dealStatus} />
      </TableCell>
      <TableCell>{`${batchesReceived} of ${totalBatches}`}</TableCell>
      <TableCell>{thousandSeparatorRound(parseInt(tokensReceived, 10))}</TableCell>
      <TableCell>{thousandSeparatorRound(parseInt(tokensExpected, 10))}</TableCell>
      <TableCell>{calculatePercentage(tokensReceived, tokensExpected)}%</TableCell>
      <TableCell>{claimers.map((c) => c.blockchain).join(', ')}</TableCell>
      <TableCell>
        {claimers.map((c, i) => (
          <React.Fragment key={i.toString()}>
            <AddressLink chainName={c.blockchain} chainAddress={c.address} />
          </React.Fragment>
        ))}
      </TableCell>
      <TableCell>{formattedDate(dateOfLastDistribution)}</TableCell>
    </TableRow>
  );
};

export default AllDealsTableRow;
