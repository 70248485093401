import React, { useState } from 'react';
import { Divider, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import RoundedButton from 'components/button/rounded-button';
import useUnlockKycMutation from '../../hooks/useUnlockKycMutation';
import './index.scss';

const UnlockKycDialog = ({ isOpen, onClose }) => {
  const [state, setState] = useState({
    successMessage: '',
    errorMessage: '',
    password: '',
  });

  const closeModal = () => {
    setState({
      successMessage: '',
      errorMessage: '',
      password: '',
    });
    onClose();
  };

  const { unlockKyc, isLoading } = useUnlockKycMutation({
    onSuccess: () => {
      setState({
        successMessage: `KYC key unlocked!`,
        errorMessage: '',
        password: '',
      });
      setTimeout(closeModal, 750);
    },
    onError: (err) => {
      setState({
        successMessage: '',
        errorMessage: err.response?.data?.message,
        password: '',
      });
    },
  });

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    unlockKyc(state.password);
  };

  const disableFormSubmit = isLoading || !state.password;

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      classes={{ paper: 'seed-modal' }}
      disableBackdropClick
    >
      <DialogTitle>Decrypt KYC Key</DialogTitle>
      <Divider />
      <DialogContent>
        {!!state.errorMessage && <h5 className="message message--error">{state.errorMessage}</h5>}
        {!!state.successMessage && (
          <h5 className="message message--success">{state.successMessage}</h5>
        )}
        <form id="seed-form" onSubmit={handleFormSubmit}>
          <div className="input-holder">
            <label>Password</label>
            <input
              type="password"
              name="password"
              value={state.password}
              onFocus={() => setState({ ...state, errorMessage: '' })}
              onChange={(e) => setState({ ...state, password: e.target.value })}
              placeholder="Enter password"
            />
          </div>
        </form>
      </DialogContent>
      <Divider />
      <DialogActions>
        <div className="action-buttons">
          <RoundedButton
            className="action-button action-button--close"
            onClick={closeModal}
            disabled={isLoading}
          >
            Close
          </RoundedButton>
          <RoundedButton
            form="seed-form"
            className="action-button action-button--submit"
            buttonType="submit"
            disabled={disableFormSubmit}
          >
            Decrypt
          </RoundedButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default UnlockKycDialog;
