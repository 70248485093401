import Tabs from 'components/tabs';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAllInvoices, getBookkeepingReports } from 'services/apiService';
import BookkeepingHeader from 'features/invoices/BookkeepingHeader';
import BookkeepingTable from 'features/invoices/BookkeepingTable';
import InvoicesTable from 'features/invoices/InvoicesTable';
import InvoiceTableHeader from 'features/invoices/InvoiceTableHeader';
import './index.scss';
import { filterReports } from './helpers';

const Invoices = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [reports, setReports] = useState([]);
  const authReducer = useSelector((state) => state.auth);
  const { isAdmin } = authReducer;

  const fetchReports = useCallback(async () => {
    const reportsRes = await getBookkeepingReports();
    if (!reportsRes.length) return;

    const allReports = filterReports(reportsRes);

    setReports(allReports);
  }, []);

  useEffect(() => {
    (async () => {
      if (selectedTabIndex === 0) {
        setInvoices(await getAllInvoices());
      } else if (selectedTabIndex === 1) {
        fetchReports();
      }
    })();
  }, [selectedTabIndex, fetchReports]);

  const tabs = [
    {
      id: 1,
      label: 'Invoices',
      content: <InvoicesTable invoices={filteredInvoices} />,
    },
    {
      id: 2,
      label: 'Bookkeeping Report',
      content: <BookkeepingTable reports={filteredReports} refetchReports={fetchReports} />,
    },
  ];

  if (!isAdmin)
    return (
      <>
        <InvoiceTableHeader invoices={invoices} onChange={setFilteredInvoices} />
        <InvoicesTable invoices={filteredInvoices} />
      </>
    );

  return (
    <div className="invoices">
      {tabs[selectedTabIndex].label === 'Invoices' ? (
        <InvoiceTableHeader invoices={invoices} onChange={setFilteredInvoices} />
      ) : (
        <BookkeepingHeader reports={reports} onChange={setFilteredReports} />
      )}
      <Tabs
        tabs={tabs}
        selectedTabIndex={selectedTabIndex}
        setSelectedTabIndex={setSelectedTabIndex}
      />
    </div>
  );
};

export default Invoices;
