import React, { useState } from 'react';
import { Divider, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import RoundedButton from 'components/button/rounded-button';
import CustomSelect from 'components/select/custom-select';
import remote from '../../../DistroWallets/remote';
import './index.scss';

const initialFormState = {
  name: '',
  seedId: '',
  model: '',
  path: '',
  walletTypeId: 1,
};

const defaultSelectOption = { id: '', name: '', value: '' };

const evmDerivationPaths = [
  { ...defaultSelectOption },
  { value: 'trezor', name: `Trezor (m/44'/60'/X'/0/0)` },
  { value: 'metamask', name: `MetaMask (m/44'/60'/0'/0/X)` },
  { value: 'mycrypto', name: `MyCrypto (m/44'/60'/0'/X)` },
];

const solanaDerivationPaths = [
  { ...defaultSelectOption },
  { value: 'phantom', name: `Phantom (m/44'/501'/X'/0')` },
];

const CreateWalletDialog = ({ isModalOpen, closeModal }) => {
  const { data: walletTypes } = remote.useGetAllWalletTypes();
  const createNewWallet = remote.usePostNewWallet();

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const clearErrorMessage = () => setErrorMessage('');
  const [derivationPaths, setDerivationPaths] = useState([]);

  const [formData, setFormData] = useState(initialFormState);
  const updateForm = (event) =>
    setFormData({ ...formData, [event.target.name]: event.target.value });

  const { data: unlockedSeeds } = remote.useGetUnlockedSeedsForSelect();

  const { data: seedWallets, isFetching: fetchingSeedWallets } = remote.useGetSeedWalletsForSelect(
    formData.seedId,
    formData.model,
    formData.walletTypeId
  );

  const handleUnlockedSeedChange = (_, value) => {
    const foundSeed = unlockedSeeds?.find((seed) => seed.value === value);
    if (!foundSeed) {
      setFormData({
        ...formData,
        seedId: defaultSelectOption.id,
        path: defaultSelectOption.id,
        walletTypeId: defaultSelectOption.id,
      });

      return;
    }

    setFormData({
      ...formData,
      seedId: foundSeed.id,
    });
  };

  const handleSeedWalletChange = (_, value) => {
    const foundWallet = seedWallets.find((wallet) => wallet.value === value);
    if (!foundWallet) {
      setFormData({
        ...formData,
        path: defaultSelectOption.id,
      });
      return;
    }
    setFormData({
      ...formData,
      path: seedWallets.find((wallet) => wallet.value === value).value,
    });
  };
  const handleWalletTypeChange = (_, value) => {
    setFormData({
      ...formData,
      walletTypeId: value,
    });

    if (value === 1) {
      setDerivationPaths(evmDerivationPaths);
    } else {
      setDerivationPaths(solanaDerivationPaths);
    }
  };

  const handleWalletModelChange = (_, value) => {
    const foundDerivationPath = derivationPaths.find((model) => model.value === value);

    setFormData({
      ...formData,
      model: foundDerivationPath ? foundDerivationPath.value : defaultSelectOption.id,
    });
  };

  const unlockedSeedsOptions = unlockedSeeds && [{ ...defaultSelectOption }, ...unlockedSeeds];

  const closeModalCb = () => {
    setErrorMessage('');
    setSuccessMessage('');
    setFormData(initialFormState);
    closeModal();
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      await createNewWallet.mutateAsync({
        name: formData.name,
        seedId: formData.seedId,
        path: formData.path,
        walletTypeId: formData.walletTypeId,
      });
      setErrorMessage('');
      setSuccessMessage(`Wallet ${formData.name} created!`);
      setTimeout(closeModalCb, 750);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
  };

  const disableFormSubmit =
    (createNewWallet.isSuccess && successMessage) ||
    createNewWallet.isLoading ||
    !Object.values(formData).every(Boolean);

  const displaySeedSelect = () => {
    if (unlockedSeeds?.length === 0) {
      return <h5 className="message message--error">No unlocked seeds</h5>;
    }

    if (unlockedSeeds?.length > 0) {
      const unlockedSeedsValue = unlockedSeedsOptions?.find(
        (option) => option.id === formData.seedId
      ).name;

      return (
        <CustomSelect
          label="Seed ID"
          name="seedId"
          value={unlockedSeedsValue}
          items={unlockedSeedsOptions}
          onSelect={handleUnlockedSeedChange}
          absolute
        />
      );
    }

    return null;
  };

  const displayWalletTypeSelect = () => {
    if (!walletTypes?.length) {
      return <h5 className="message message--error">No wallet types</h5>;
    }

    return (
      <CustomSelect
        label="Wallet Type ID"
        name="walletTypeId"
        value={formData.walletTypeId}
        items={walletTypes.map((wt) => ({
          value: wt.id,
          name: wt.name,
        }))}
        onSelect={handleWalletTypeChange}
        absolute
      />
    );
  };

  const displayModelSelect = () => {
    if (unlockedSeeds?.length === 0) {
      return <h5 className="message message--error">No unlocked seeds</h5>;
    }

    if (unlockedSeeds?.length > 0 && formData.seedId) {
      return (
        <CustomSelect
          label="Model"
          name="model"
          value={formData.model}
          items={derivationPaths}
          onSelect={handleWalletModelChange}
          absolute
        />
      );
    }
    return null;
  };

  const displayWalletSelect = () => {
    if (fetchingSeedWallets) {
      return <h5 className="message">Loading seed wallets</h5>;
    }

    if (seedWallets?.length > 0) {
      const seedWalletsOptions = [{ ...defaultSelectOption }, ...seedWallets];

      const formattedSeedWalletsOptions = seedWalletsOptions.map((option) => ({
        ...option,
        name: option?.id ? `${option.value}  -  ${option.id}` : '',
      }));

      return (
        <CustomSelect
          label="Wallet"
          name="path"
          value={formData.path}
          items={formattedSeedWalletsOptions}
          onSelect={handleSeedWalletChange}
          absolute
        />
      );
    }

    return null;
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={closeModalCb}
      classes={{ paper: 'seed-modal' }}
      disableBackdropClick
    >
      <DialogTitle>New wallet</DialogTitle>
      <Divider />
      <DialogContent>
        {!!errorMessage && <h5 className="message message--error">{errorMessage}</h5>}
        {!!successMessage && <h5 className="message message--success">{successMessage}</h5>}
        <form id="seed-form" onSubmit={handleFormSubmit}>
          <div className="input-holder">
            <label>Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onFocus={clearErrorMessage}
              onChange={updateForm}
              placeholder="Enter name"
            />
          </div>
          {displaySeedSelect()}
          {displayWalletTypeSelect()}
          {displayModelSelect()}
          {displayWalletSelect()}
        </form>
      </DialogContent>
      <Divider />
      <DialogActions>
        <div className="action-buttons">
          <RoundedButton
            className="action-button action-button--close"
            onClick={closeModalCb}
            disabled={createNewWallet.isLoading}
          >
            Close
          </RoundedButton>
          <RoundedButton
            form="seed-form"
            className="action-button action-button--submit"
            buttonType="submit"
            disabled={disableFormSubmit}
          >
            Create
          </RoundedButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default CreateWalletDialog;
