import { useQuery } from 'react-query';
import remote from '../remote';

function useUsersLegalData({ onSuccess, onError } = {}) {
  const { data, isFetching } = useQuery({
    queryKey: ['users-legal'],
    queryFn: remote.getUsersLegalData,
    onSuccess,
    onError,
  });

  return {
    data,
    isLoading: isFetching,
  };
}

export default useUsersLegalData;
