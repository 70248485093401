export function filterReports(reports) {
  const { year, month } = getLastMonthAndYear();

  const reportExists = findReportForYearAndMonth(reports, year, month);

  const filteredReports = [];

  if (!reportExists) {
    filteredReports.push({ year, month, isCreated: false });
  }

  reports.forEach((report) => {
    filteredReports.push({ ...report, isCreated: true });
  });

  return filteredReports;
}

function getLastMonthAndYear() {
  const date = new Date();

  date.setMonth(date.getMonth() - 1);

  return { year: date.getFullYear(), month: date.getMonth() + 1 };
}

function findReportForYearAndMonth(reports, year, month) {
  return reports.find(
    ({ year: reportYear, month: reportMonth }) => +reportYear === year && +reportMonth === month
  );
}
