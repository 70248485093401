const balancesTableHead = [
  {
    column: 'walletId',
    tooltipTitle: 'Wallet id',
    label: 'Id',
    defaultSortField: true,
    sort: (a, b) => (parseInt(b.walletId, 10) > parseInt(a.walletId, 10) ? -1 : 1),
  },
  {
    column: 'dealName',
    label: 'Deal Name',
    sort: (a, b) => (b.dealName.trim().toLowerCase() > a.dealName.trim().toLowerCase() ? 1 : -1),
  },
  {
    column: 'dealStatus',
    label: 'Deal Status',
    sort: (a, b) =>
      b.dealStatus.trim().toLowerCase() > a.dealStatus.trim().toLowerCase() ? 1 : -1,
  },
  {
    column: 'walletName',
    label: 'Wallet Name',
    sort: (a, b) =>
      b.walletName.trim().toLowerCase() > a.walletName.trim().toLowerCase() ? 1 : -1,
  },
  {
    column: 'walletType',
    label: 'Wallet Type',
    sort: (a, b) =>
      b.walletType.name.trim().toLowerCase() > a.walletType.name.trim().toLowerCase() ? 1 : -1,
  },
  {
    column: 'seedName',
    label: 'Seed Name',
    sort: (a, b) => (b.seedName.trim().toLowerCase() > a.seedName.trim().toLowerCase() ? 1 : -1),
  },
  {
    column: 'seedPath',
    label: 'Seed Path',
    sort: (a, b) => (b.seedPath.trim().toLowerCase() > a.seedPath.trim().toLowerCase() ? 1 : -1),
  },
  {
    column: 'receiveAddress',
    label: 'Receive Address',
    sort: (a, b) =>
      b.walletAddress.trim().toLowerCase() > a.walletAddress.trim().toLowerCase() ? 1 : -1,
  },
  {
    column: 'claimerAddress',
    label: 'Claimer Address',
    sort: (a, b) =>
      b.claimerAddress.trim().toLowerCase() > a.claimerAddress.trim().toLowerCase() ? 1 : -1,
  },
  {
    column: 'token',
    label: 'Token',
    sort: (a, b) => (b.chain.trim().toLowerCase() > a.chain.trim().toLowerCase() ? 1 : -1),
  },
  {
    column: 'balance',
    label: 'Balance',
    sort: (a, b) => (parseFloat(b.balance) > parseFloat(a.balance) ? 1 : -1),
  },
];

export default { balancesTableHead };
