import React from 'react';
import UsersLegal from '../screens/UsersLegal';
import ReportTable from '../screens/Reports/ReportTable';

const useAMLTabs = () => {
  return [
    {
      id: 1,
      label: 'Users',
      content: <UsersLegal />,
    },
    {
      id: 2,
      label: 'Monthly Report',
      content: <ReportTable />,
    },
  ];
};

export default useAMLTabs;
