import React, { useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateSystemPart } from 'store/actions';
import { SYSTEM_PARTS } from 'features/sidebar/LeftSideBar/utils';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import RoundedButton from 'components/button/rounded-button';
import Tabs from 'components/tabs';
import remote from '../AllDeals/remote';
import DealInfo from './DealInfo';
import Distributions from './Distributions';
import './index.scss';
import BatchSchedule from './BatchSchedule';
import History from './History';

const getDealId = (deal) => +deal.id.split('-')[1];

const AllDealsSingle = () => {
  const { params } = useRouteMatch();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const isOldDeal = searchParams.get('isOldDeal') === 'true';

  const history = useHistory();
  const goBack = () => {
    history.goBack();

    history.replace(history.location.state.pathname, {
      activeTab: history.location.state.activeTab,
    });
  };

  const { data: deal } = remote.useGetSingleDeal(+params.dealId, isOldDeal);

  const dispatch = useDispatch();
  useLayoutEffect(() => {
    dispatch(updateSystemPart(SYSTEM_PARTS.DISTRO_MANAGER));
  }, [dispatch]);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  if (!deal) return null;

  const tabs = [
    {
      id: 1,
      label: 'Deal Info',
      content: <DealInfo dealId={getDealId(deal)} isOldDeal={isOldDeal} />,
    },
    {
      id: 2,
      label: 'History',
      content: <History dealId={getDealId(deal)} isOldDeal={isOldDeal} />,
    },
    {
      id: 3,
      label: 'Batch Schedule',
      content: <BatchSchedule dealId={getDealId(deal)} isOldDeal={isOldDeal} />,
    },
    {
      id: 4,
      label: 'Compare',
      content: <Distributions dealId={getDealId(deal)} isOldDeal={isOldDeal} />,
    },
  ];

  return (
    <div className="all-deals-page">
      <div className="deals-header">
        <div className="deals-header-left d-flex">
          <div className="deals-header__title vertical-center">
            <span className="title-span">All deals</span>/ {deal.dealName}
          </div>
        </div>

        <RoundedButton className="back-button" type="primary" onClick={goBack}>
          Go back
        </RoundedButton>
      </div>

      <Tabs
        tabs={tabs}
        selectedTabIndex={selectedTabIndex}
        setSelectedTabIndex={setSelectedTabIndex}
      />
    </div>
  );
};

export default AllDealsSingle;
