import React from 'react';
import { ethers } from 'ethers';
import moment from 'moment';
import TxHashLink from 'components/TxHashLink';
import useTableColumns from '../../hooks/useTableColumns';

const useContributionTableColumns = () => {
  const columns = [
    {
      column: 'dealName',
      label: 'Deal Name',
      type: 'string',
      accessor: (row) => row.deal.name,
      value: (row) => row.deal.name,
    },
    {
      column: 'blockchain',
      label: 'Blockchain',
      type: 'string',
      accessor: (row) => row.deal.blockchain.name,
      value: (row) => row.deal.blockchain.name,
    },
    {
      column: 'amount',
      label: 'Amount',
      type: 'number',
      accessor: (row) => ethers.utils.formatUnits(row.amount, 'mwei'),
      value: (row) => +ethers.utils.formatUnits(row.amount, 'mwei'),
    },
    {
      column: 'txHash',
      label: 'Tx Hash',
      type: 'string',
      accessor: (row) => <TxHashLink chainId={row.deal.blockchain.chainId} hash={row.txHash} />,
      value: (row) => row.txHash,
    },
    {
      column: 'createdAt',
      label: 'Created At',
      type: 'date',
      accessor: (row) => moment(row.createdAt).format('YYYY-MM-DD, HH:mm'),
      value: (row) => row.createdAt,
    },
  ];

  return useTableColumns(columns);
};

export default useContributionTableColumns;
