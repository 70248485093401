import { apiGet, apiPost, baseUrl } from 'services/apiService';

const getUsersLegalData = async () => {
  const response = await apiGet(`${baseUrl}/api/admin/legal`);

  return response.data;
};

const getUserLegalDetails = async (address) => {
  const response = await apiGet(`${baseUrl}/api/admin/legal/${address}`);

  return response.data;
};

const getUserClaims = async (address) => {
  const response = await apiGet(`${baseUrl}/api/admin/legal/users-claims/${address}`);

  return response.data;
};

const getUserContributions = async (address) => {
  const response = await apiGet(`${baseUrl}/api/admin/legal/users-contributions/${address}`);

  return response.data;
};

const getUserLogins = async (address) => {
  const response = await apiGet(`${baseUrl}/api/admin/legal/users-logins/${address}`);

  return response.data;
};

const getRiskReportData = async (type, address) => {
  const response = await apiGet(`${baseUrl}/api/admin/legal/${type}/${address}/data`);

  return response.data;
};

const getRiskReportFile = async (type, address) => {
  const response = await apiGet(`${baseUrl}/api/admin/legal/${type}/${address}/report`, {
    responseType: 'blob',
  });

  return response.data;
};

const decryptKycKey = async (password) => {
  const response = await apiPost(`${baseUrl}/api/admin/legal/decrypt-kyc-private-key`, {
    password,
  });

  return response.data;
};

export default {
  getUsersLegalData,
  getUserLegalDetails,
  getUserContributions,
  getRiskReportData,
  getRiskReportFile,
  getUserLogins,
  getUserClaims,
  decryptKycKey,
};
