const batchScheduleTableHead = [
  {},
  {
    column: 'date',
    label: 'Date (UTC)',
    sort: (a, b) => {
      if (!a.date) {
        return 1;
      }
      if (!b.date) {
        return -1;
      }
      return new Date(b.date) > new Date(a.date) ? 1 : -1;
    },
    defaultSortField: true,
  },
  {
    column: 'dealName',
    label: 'Name',
    sort: (a, b) => (b.dealName.trim().toLowerCase() > a.dealName.trim().toLowerCase() ? 1 : -1),
  },
  {
    column: 'ticker',
    label: 'Ticker',
    sort: (a, b) => (b.ticker.trim().toLowerCase() > a.ticker.trim().toLowerCase() ? 1 : -1),
  },
  {
    column: 'blockchain',
    label: 'Chain',
    sort: (a, b) =>
      b.blockchain?.trim()?.toLowerCase() > a.blockchain?.trim()?.toLowerCase() ? 1 : -1,
  },
  {
    column: 'expectedBatch',
    label: 'Expected batch',
    sort: (a, b) =>
      b.expectedBatch.trim().toLowerCase() > a.expectedBatch.trim().toLowerCase() ? 1 : -1,
  },
  {
    column: 'expectedTokens',
    label: 'Expected',
    sort: (a, b) => (parseFloat(b.expectedTokens) > parseFloat(a.expectedTokens) ? 1 : -1),
  },
  {
    column: 'expectedTokensPercentage',
    label: 'Expected (%)',
    sort: (a, b) =>
      parseFloat(b.expectedTokensPercentage) > parseFloat(a.expectedTokensPercentage) ? 1 : -1,
  },
  {
    column: 'totalTokensReceived',
    label: 'Total Received',
    sort: (a, b) =>
      parseFloat(b.totalTokensReceived) > parseFloat(a.totalTokensReceived) ? 1 : -1,
  },
  {
    column: 'totalTokensExpected',
    label: 'Total Expected',
    sort: (a, b) =>
      parseFloat(b.totalTokensExpected) > parseFloat(a.totalTokensExpected) ? 1 : -1,
  },
  {
    column: 'totalTokensReceivedPercentage',
    label: 'Total Received (%)',
    sort: (a, b) =>
      parseFloat(b.totalTokensReceivedPercentage) > parseFloat(a.totalTokensReceivedPercentage)
        ? 1
        : -1,
  },
];

const historyTableHead = [
  {
    column: 'date',
    label: 'Date (UTC)',
    sort: (a, b) => {
      if (!a.date) {
        return 1;
      }
      if (!b.date) {
        return -1;
      }
      return new Date(b.date) > new Date(a.date) ? 1 : -1;
    },
    defaultSortField: true,
  },
  {
    column: 'dealName',
    label: 'Name',
    sort: (a, b) => (b.dealName.trim().toLowerCase() > a.dealName.trim().toLowerCase() ? 1 : -1),
  },
  {
    column: 'ticker',
    label: 'Ticker',
    sort: (a, b) => (b.ticker.trim().toLowerCase() > a.ticker.trim().toLowerCase() ? 1 : -1),
  },
  {
    column: 'blockchain',
    label: 'Chain',
    sort: (a, b) =>
      b.blockchain.trim().toLowerCase() > a.blockchain.trim().toLowerCase() ? 1 : -1,
  },
  {
    column: 'batch',
    label: 'Batch',
    sort: (a, b) => (parseInt(b.currentBatch, 10) > parseInt(a.currentBatch, 10) ? 1 : -1),
  },
  {
    column: 'received',
    label: 'Received',
    sort: (a, b) => (parseFloat(b.received) > parseFloat(a.received) ? 1 : -1),
  },
  {
    column: 'receivedPercentage',
    label: 'Received (%)',
    sort: (a, b) => (parseFloat(b.receivedPercentage) > parseFloat(a.receivedPercentage) ? 1 : -1),
  },
  {
    column: 'totalReceived',
    label: 'Total Received',
    sort: (a, b) => (parseFloat(b.totalReceived) > parseFloat(a.totalReceived) ? 1 : -1),
  },
  {
    column: 'totalExpected',
    label: 'Total Expected',
    sort: (a, b) => (parseFloat(b.totalExpected) > parseFloat(a.totalExpected) ? 1 : -1),
  },
  {
    column: 'totalReceivedPercentage',
    label: 'Total Received (%)',
    sort: (a, b) =>
      parseFloat(b.totalReceivedPercentage) > parseFloat(a.totalReceivedPercentage) ? 1 : -1,
  },
];

export default { batchScheduleTableHead, historyTableHead };
