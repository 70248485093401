import React from 'react';

import '../../index.scss';
import useUsersLegalData from '../../hooks/useUsersLegalData';
import UsersLegalContent from '../../components/UsersLegalContent';

const UsersLegal = () => {
  const { data: users } = useUsersLegalData();

  return <div className="all-deals-page">{users && <UsersLegalContent users={users} />}</div>;
};

export default UsersLegal;
