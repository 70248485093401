import { useMutation, useQueryClient } from 'react-query';
import remote from '../remote';

function useUnlockKycMutation({ onSuccess, onError } = {}) {
  const queryClient = useQueryClient();

  const { mutateAsync: unlockKyc, isLoading } = useMutation({
    mutationFn: remote.decryptKycKey,
    onSuccess: () => {
      queryClient.invalidateQueries('users-legal');

      if (onSuccess) onSuccess();
    },
    onError,
  });

  return {
    unlockKyc,
    isLoading,
  };
}

export default useUnlockKycMutation;
