import { useQuery } from 'react-query';
import { filterReports } from 'screens/Invoices/helpers';
import { getHANFAReports } from 'services/apiService';

export function useAllHanfaReportQuery({ enabled } = { enabled: true }) {
  const { data: reports, isLoading } = useQuery({
    queryKey: ['hanfa-reports'],
    queryFn: async () => {
      const reports = await getHANFAReports();

      return filterReports(reports);
    },
    enabled,
  });

  return { reports, isLoading };
}
